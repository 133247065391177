import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ActionsBox from "./ActionsBox";

const Sidebar = () => {
  return (
    <Grid item xs={12} md={3}>
      <Box>
        <Card sx={{ display: "flex" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              Disorderly Conduct
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Members: 61
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Upcoming Events: 15
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Scheduled Events: 19
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <ActionsBox />
    </Grid>
  );
};

export default Sidebar;
