import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Sidebar from "../Portal/Sidebar";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AuthProvider, useAuthContext } from "../AuthContext";
import CharacterRow from "./CharacterRow";
import * as React from "react";
import { Avatar } from "@mui/material";

const Characters = () => {
  const [characters, setCharacters] = React.useState<any>("");
  let promiseCall: any = null;
  const { user, loading } = useAuthContext();

  return (
    <Grid container spacing={4} mt={2}>
      <Sidebar />
      <Grid item xs={12} md={9}>
        <Box>
          <Card sx={{ display: "flex" }}>
            <CardContent sx={{ flex: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button href="/characters/add">Add New Character</Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Class</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Item Level</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <CharacterRow />
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Characters;
