import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

const ActionsBox = () => {
  return (
    <Box mt={4}>
      <Card sx={{ display: "flex" }}>
        <CardContent sx={{ flex: 1 }}>
          <Typography variant="subtitle1" color="text.primary">
            <Button
              variant="contained"
              href="/characters"
              fullWidth
              sx={{ mt: 1, mb: 1 }}
            >
              Characters
            </Button>
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            <Button
              variant="contained"
              href="/events"
              fullWidth
              sx={{ mt: 1, mb: 1 }}
            >
              Events
            </Button>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ActionsBox;
