import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Sidebar from "./Sidebar";

const Portal = () => {
  return (
    <Grid container spacing={4} mt={2}>
      <Sidebar />
      <Grid item xs={12} md={9}>
        <Box>
          <Card sx={{ display: "flex" }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography component="h2" variant="h5">
                My Dashboard
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Portal;
