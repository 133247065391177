/* eslint-disable react/prop-types */
import * as React from "react";
import axios from "axios";

const AuthContext = React.createContext({});

interface IApp {
  user: any;
  loading: boolean;
}

interface Props {
  children: React.ReactElement;
}
export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = React.useState<IApp>({ user: null, loading: true });
  let promiseCall: any = null;

  React.useEffect(() => {
    if (promiseCall) return;

    promiseCall = true;

    axios
      .get("/user/getData")
      .then((res) => {
        console.log("res" + res.data.login);

        if (res.data.login) {
          setAuth({
            loading: false,
            user: res.data.user,
          });
        } else {
          setAuth({
            loading: false,
            user: null,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () =>
  React.useContext<{ [key: string]: any }>(AuthContext);

export default AuthContext;
