import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import axios from "axios";

const CharacterRow = () => {
  const [rows, setRows] = React.useState<any>([]);
  let promiseCall: any = null;

  const fetchData = async () => {
    if (promiseCall) return;

    promiseCall = true;

    axios
      .get("/character/getAll")
      .then((res) => {
        setRows(
          res.data.map((character: any) => {
            return {
              avatar:
                "/assets/classes/" + character.type.name.toLowerCase() + ".png",
              name: character.name,
              itemlevel: character.item_level,
              className: character.type.name,
              key: character._id,
            };
          })
        );
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id: any) => {
    promiseCall = false;

    if (!id) return;

    axios.delete("/character/" + id).then((res) => fetchData());
  };

  return (
    <TableBody>
      {rows.map((row: any) => (
        <TableRow
          key={row.key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row" align="center">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Avatar
                src={row.avatar}
                sx={{
                  width: 40,
                  height: 40,
                  mr: 1.5,
                }}
                variant="square"
              />{" "}
              {row.className}
            </Box>
          </TableCell>
          <TableCell align="center">{row.name}</TableCell>
          <TableCell align="center">{row.itemlevel}</TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="delete"
              size="large"
              data-id={row.key}
              onClick={(e) => handleDelete(row.key)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default CharacterRow;
