import * as React from "react";
import { useAuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";

interface IProps {
  children: any;
}

const ProtectedRoute = ({ children }: IProps) => {
  const { user, loading } = useAuthContext();

  if (loading) return;

  if (!user) return <Navigate to="/" replace />;

  return children;
};

export default ProtectedRoute;
