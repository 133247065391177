import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Sidebar from "../Portal/Sidebar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import * as React from "react";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IClasses {
  name: string;
  _id: number;
}

const TypeComponent = (type: IClasses) => {
  debugger;
  //const avatarUrl = "/assets/classes/" + type.name.toLowerCase() + ".png";
  return <div></div>;
  /*return (
    <div>
      <MenuItem value={type._id} key={type._id}>
        <Avatar
          src={"logo.png"}
          style={{
            width: 30,
            height: 30,
          }}
          variant="square"
        />
        {" Bard"}
      </MenuItem>
    </div>
  );*/
};

const AddCharacter = () => {
  const [classes, setClasses] = React.useState<any>("");
  const [type, setType] = React.useState("");
  const [name, setName] = React.useState<String>();
  const [itemLevel, setItemLevel] = React.useState<Number>();
  let promiseCall: any = null;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (promiseCall) return;

    promiseCall = true;

    axios
      .get("/type/get")
      .then((res) => {
        setClasses(res.data.types);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .post("/character/create", {
        name: data.get("characterName"),
        itemLevel: data.get("itemLevel"),
        type: data.get("type"),
      })
      .then((res) => {
        navigate("/characters", { replace: true });
      })
      .catch((e) => console.error(e));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <Grid container spacing={4} mt={2}>
      <Sidebar />
      <Grid item xs={12} md={9}>
        <Card>
          <CardContent>
            <Box
              sx={{
                marginTop: 8,
                marginLeft: 25,
                marginRight: 25,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Register Character
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="characterName"
                  label="Character Name"
                  name="characterName"
                  inputProps={{
                    autoComplete: "none",
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="itemLevel"
                  label="Item Level"
                  type="number"
                  id="itemLevel"
                />
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Class"
                  fullWidth
                  required
                  onChange={handleChange}
                  value={type}
                  name="type"
                  sx={{
                    ".MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  {classes &&
                    classes.length &&
                    classes.map((type: IClasses) => {
                      let avatarUrl =
                        "/assets/classes/" + type.name.toLowerCase() + ".png";

                      return (
                        <MenuItem
                          value={type._id}
                          key={type._id}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            src={avatarUrl}
                            sx={{
                              width: 25,
                              height: 25,
                              mr: 1,
                            }}
                            variant="square"
                          />
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Create
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddCharacter;
