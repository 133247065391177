import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { FunctionComponent, useState } from "react";

import Home from "../Home";
import Signin from "../signin/Signin";
import Portal from "../Portal";
import Characters from "../Characters";
import AddCharacter from "../AddCharacter";
import ProtectedRoute from "../ProtectedRoute";

import Container from "@mui/material/Container";
import Header from "./Header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

class Main extends React.Component<{}> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<Signin />} />
              <Route
                path="/portal"
                element={
                  <ProtectedRoute>
                    <Portal />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/characters/add"
                element={
                  <ProtectedRoute>
                    <AddCharacter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/characters"
                element={
                  <ProtectedRoute>
                    <Characters />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </Container>
      </ThemeProvider>
    );
  }
}

export default Main;
