import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useAuthContext } from "../AuthContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const LoginButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, loading } = useAuthContext();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <Button>Loading</Button>;
  }

  if (user) {
    return (
      <div>
        <Button
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenuClick}
          startIcon={
            <Avatar
              src={
                "https://cdn.discordapp.com/avatars/" +
                user.discordId +
                "/" +
                user.avatar
              }
            />
          }
        >
          {user.username}#{user.discriminator}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <Link href="/portal" color="inherit" underline="none">
              Portal
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              href="http://localhost:3001/user/logout"
              color="inherit"
              underline="none"
            >
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  if (!user) {
    return (
      <Button
        href="https://discord.com/api/oauth2/authorize?client_id=991504354517450762&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Fuser%2Fauthorize&response_type=code&scope=identify%20email"
        variant="outlined"
        size="small"
      >
        Sign in
      </Button>
    );
  }

  return null;
};

const Header = () => (
  <React.Fragment>
    <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Button href="/" size="small">
        Home
      </Button>
      <Typography variant="h5" align="center" noWrap sx={{ flex: 1 }}>
        Lost Ark - Guilds
      </Typography>
      <LoginButton />
    </Toolbar>
  </React.Fragment>
);

export default Header;
